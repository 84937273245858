import {get, post, put,Delete} from "@/api/axios";

export const list = params => get('/web/build/manage/list', params);

export const cityList = params => get('/web/build/manage/city/list', params);

export const countyList = params => get(`/web/build/manage/city/county/list/${params.id}`, '');

export const countyBusinessList = params => get(`/web/build/manage/city/county/business/list/${params.id}`, '');

export const metroList = params => get(`/web/build/manage/city/metro/list/${params.id}`, '');

export const metroStationList = params => get(`/web/build/manage/city/metro/station/list/${params.id}`, '');

export const add = params => post('/web/build/manage/add', params);

export const agentList = params => get('/web/build/manage/agent/list', params);

// /web/build/manage/view/{id} get
export const view = params => get(`/web/build/manage/view/${params.id}`, '');

// /web/build/manage/update put
export const update = params => put('/web/build/manage/update', params);

// /web/build/manage/delete/{id} delete
export const deleteNew = params => Delete(`/web/build/manage/delete/${params.id}`, '');