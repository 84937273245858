<template>
  <div class="main-content">
    <avue-crud
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
    >
      <template #header>
        <div class="flex align-items flex-wrap">
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="primary" icon="el-icon-plus" @click="handleCreate">新增</el-button>
          </div>
          <div class="marginRight10 marginBottom10 width200">
            <el-select size="medium" v-model="searchData.cityId" clearable @change="changeCity">
              <el-option label="市" value="">
              </el-option>
              <el-option
                  v-for="item in cityData"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="marginRight10 marginBottom10 width200">
            <el-select size="medium" v-model="searchData.countyId" clearable @change="changeCounty"
                       @visible-change="visibleChange">
              <el-option label="区县" value="">
              </el-option>
              <el-option
                  v-for="item in countyData"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="marginRight10 marginBottom10 width200">
            <el-select size="medium" v-model="searchData.businessId" clearable
                       @visible-change="visibleChange">
              <el-option label="商圈" value="">
              </el-option>
              <el-option
                  v-for="item in businessData"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
          <el-input size="medium" v-model="searchData.name" placeholder="输入楼盘名称/楼盘号/新房经纪人手机号" class="marginRight10 marginBottom10 width300"/>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="info" icon="el-icon-refresh-right" @click="handleReset">重置</el-button>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" style="background: #f59a23;color: #ffffff;border-color: #f59a23" icon="el-icon-upload2" @click="handleImport">批量导入</el-button>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" style="background: #c280ff;color: #ffffff;border-color: #c280ff" icon="el-icon-download" @click="handleExport">导出</el-button>
          </div>
        </div>
        <div class="flex marginBottom10">
          <div class="span">楼盘总数: {{ page.total }}</div>
        </div>
        <div class="flex marginBottom10">
          <div class="span">批量导入楼盘号系统生成无需填写</div>
        </div>

      </template>
      <template slot="menu" slot-scope="{ row }">
        <el-button type="text" size="mini" icon="el-icon-edit" @click="handleEdit(row)">编辑</el-button>
        <el-button type="text" size="mini" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
      </template>
    </avue-crud>

    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
      <div class="flex align-items justify-content-center marginBottom20">
<!--        <el-button type="primary" size="medium" >选择文件</el-button>-->

        <el-upload
            class="upload-demo"
            :drag="true"
            action="/api/web/build/manage/template/import"
            :show-file-list="false"
            :on-success="handleSuccess"
            :before-upload="beforeUpload">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <div class="flex align-items justify-content-center">
        <el-button type="text" size="medium" @click="handleDownload">下载文件模板</el-button>
      </div>
    </el-dialog>

    <el-dialog
        title="提示"
        :visible.sync="dialogVisibleOne"
        width="30%"
        :before-close="handleCloseOne">
      <div class="flex align-items justify-content-center marginBottom30">
        <span>导入成功</span>
      </div>
      <div class="flex align-items justify-content-center">
        <el-button type="primary" size="medium" @click="handleCloseOne">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog
        title="提示"
        :visible.sync="dialogVisibleTwo"
        width="30%"
        :before-close="handleCloseTwo">
      <div class="flex align-items justify-content-center">
        <span>部分数据已导入成功</span>
      </div>
      <div class="flex align-items justify-content-center marginBottom10">
        <span>问题数据请通过下载获取</span>
      </div>
      <div class="flex align-items justify-content-center marginBottom10">
        <el-button type="text" size="medium" @click="handleDownloadProblem">下载问题数据</el-button>
      </div>
      <div class="flex align-items justify-content-center">
        <el-button type="primary" size="medium" @click="handleCloseTwo">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog
        title="提示"
        :visible.sync="dialogVisibleThree"
        width="30%"
        :before-close="handleCloseThree">
      <div class="flex align-items justify-content-center marginBottom10">
        <span>全部导入失败</span>
      </div>
      <div class="flex align-items justify-content-center marginBottom10">
        <el-button type="text" size="medium" @click="handleDownloadProblem">下载问题数据</el-button>
      </div>
      <div class="flex align-items justify-content-center">
        <el-button type="primary" size="medium" @click="handleCloseThree">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog
        title="提示"
        :visible.sync="dialogVisibleFour"
        width="30%"
        :before-close="handleCloseFour">
      <div class="flex align-items justify-content-center marginBottom10">
        <span>数据为空导入失败</span>
      </div>
      <div class="flex align-items justify-content-center">
        <el-button type="primary" size="medium" @click="handleCloseFour">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {list, cityList, countyList, countyBusinessList,deleteNew} from "@/api/build/manage"
import qs from "qs";
export default {
  name: "index",
  data(){
    return{
      cityData: [],
      countyData: [],
      businessData: [],
      searchData: {
        cityId: '',
        countyId: '',
        businessId: '',
        name: ''
      },
      page:{
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      option: {
        title:'楼盘库管理',
        titleSize:'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        size: 'small',
        selection: false, // 显示多选框
        page: true, // 显示分页
        align:'center',
        menuAlign:'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: '楼盘号',
            prop: 'code'
          },
          {
            label: '楼盘名称',
            prop: 'name'
          },
          {
            label: '省/市',
            prop: 'cityName'
          },
          {
            label: '区县',
            prop: 'countyName'
          },
          {
            label: '商圈',
            prop: 'businessName'
          },
          {
            label: '详细地址',
            prop: 'detailAddress'
          },
          {
            label: '新房经纪人',
            prop: 'agentName'
          },
          {
            label: '新房经纪人手机号',
            prop: 'agentPhone'
          },
          {
            label: '状态',
            prop: 'state',
            formatter: (row) => {
              return row.state === 1 ? '上架' : '下架'
            }
          },
          {
            label: '排序',
            prop: 'sort'
          }
        ]
      },

      dialogVisible: false,
      dialogVisibleOne: false,
      dialogVisibleTwo: false,
      problemId: '',
      dialogVisibleThree: false,
      dialogVisibleFour: false,
    }
  },
  async mounted() {
    await this.cityList();
  },
  methods: {
    onLoad(){
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      }).then(res => {
        this.showLoading = false;
        if(res.code === 200){
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      })
    },
    // 搜索
    handleSearch(){
      this.page.currentPage = 1;
      this.onLoad()
    },
    // 重置
    handleReset(){
      this.searchData = {
        cityId: '',
        countyId: '',
        businessId: '',
        name: ''
      }
      this.page.currentPage = 1;
      this.countyData = [];
      this.businessData = [];
      this.onLoad()
    },
    // 新增
    handleCreate(){
      // console.log('新增')
      this.$router.push({
        path: '/build/manage/info'
      })
    },
    // 编辑
    handleEdit(row){
      // console.log('编辑',row)
      this.$router.push({
        path: '/build/manage/info',
        query: {
          id: row.id
        }
      })
    },
    // 删除
    handleDelete(row){
      // console.log('删除',row)
      this.$confirm('是否删除此楼盘库数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteNew({
          id: row.id
        }).then(res => {
          if(res.code === 200){
            this.$message.success('删除成功');
            this.onLoad();
          }else{
            this.$message.error(res.msg);
          }
        })
      })
    },
    // 批量导入
    handleImport(){
      console.log('批量导入')
      this.dialogVisible = true;
    },
    // 导出
    handleExport(){
      window.open(`/api/web/build/manage/export?${qs.stringify(this.searchData)}`,'_self');
    },
    handleSuccess(res, file) {
      // console.log(1111111,res, file);
      this.dialogVisible = false;
      if(res.code === 200){
        setTimeout(()=>{
          if(res.data.state === 1) {
            this.dialogVisibleOne = true;
          }else if(res.data.state === 2){
            this.problemId = res.data.id;
            this.dialogVisibleTwo = true;
          }else if(res.data.state === 3){
            this.problemId = res.data.id;
            this.dialogVisibleThree = true;
          }else{
            this.problemId = res.data.id;
            this.dialogVisibleFour = true;
          }
        },500)
      }
    },
    beforeUpload(file) {
      // 文件类型
      const isType = file.type === 'application/vnd.ms-excel';
      const isTypeComputer = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const fileType = isType || isTypeComputer;
      if (!fileType) {
        this.$message.error('上传文件只能是xls/xlsx格式！');
      } // 文件大小限制为2M
      const fileLimit = file.size / 1024 / 1024 < 20;
      if (!fileLimit) {
        this.$message.error('上传文件大小不超过20M！');
      }
      return fileType && fileLimit;
    },
    // 下载文件模板
    handleDownload(){
      // console.log('下载文件模板')
      window.open('/api/web/build/manage/template/export', '_self')
    },
    // 关闭面板
    handleClose(){
      this.dialogVisible = false;
    },
    handleCloseOne(){
      this.dialogVisibleOne = false;
      this.onLoad();
    },
    handleCloseTwo(){
      this.dialogVisibleTwo = false;
    },
    handleCloseThree(){
      this.dialogVisibleThree = false;
    },
    handleCloseFour(){
      this.dialogVisibleFour = false;
    },
    // 下载问题数据
    handleDownloadProblem(){
      // console.log('下载问题数据')
      window.open(`/api/web/build/manage/template/error/export?id=${this.problemId}`, '_self')
    },
    async cityList(){
      const { data } = await cityList()
      this.cityData = data || []
    },
    async changeCity(e){
      await this.countyList(e)
      this.searchData.countyId = '';
      this.searchData.businessId = '';
      this.businessData = [];
    },
    async countyList(id){
      if(id){
        const { data } = await countyList({id: id})
        console.log(data)
        this.countyData = data || []
      }else{
        this.countyData = []
      }
    },
    async changeCounty(e){
      await this.countyBusinessList(e)
      this.searchData.businessId = ''
    },
    async countyBusinessList(id){
      if(id){
        const { data } = await countyBusinessList({id: id})
        console.log(data)
        this.businessData = data || []
      }else{
        this.businessData = []
      }
    },
    visibleChange(e){
      if(e && !this.searchData.cityId){
        this.$message.warning('请先选择省/市')
      }
    }
  }
}
</script>

<style scoped>

</style>